import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["accordion"];

  connect() {
    this.closeAllAccordions();
  }

  closeAllAccordions() {
    this.accordionTargets.forEach((accordion) => {
      accordion.removeAttribute("open");
    });
  }

  toggle(event) {
    const carat = event.target.querySelector(".accordion__toggle");
    this.rotateCarat(carat);
    if (event.target.open && this.openAccordion !== event.target) {
      this.openAccordion?.removeAttribute("open");
      this.openAccordion = event.target;
    }
    if (event.target.open) {
      event.target.querySelector("[autofocus]")?.focus();
    }
  }

  rotateCarat(carat) {
    if (carat.classList.contains("accordion__toggle--expanded")) {
      carat.classList.remove("accordion__toggle--expanded");
    } else {
      carat.classList.add("accordion__toggle--expanded");
    }
  }
}
