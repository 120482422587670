export const clearTargetTurboFrame = (element) => {
  const targetFrame = element.getAttribute("data-turbo-frame");
  const targetContent = document.querySelector(
    `#${targetFrame} .turbo_loader__content`,
  );
  // We remove what was previously inside the turbo-frame to prevent a flash of the old content
  // after the new content has loaded.
  if (targetContent) {
    // Delay the removal to the next tick so that events are allowed to fully propagate first.
    setTimeout(() => targetContent.remove(), 0);
  }
};
