import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.mode = this.element.getAttribute("data-sidebar-mode") || "expanded";
  }

  toggleSidebarState() {
    this.mode = this.mode === "expanded" ? "collapsed" : "expanded";
    this.element.setAttribute("data-sidebar-mode", this.mode);
  }

  navigate(event) {
    const link = event.target;
    const url = new URL(link.href);
    url.searchParams.set("sidebar", this.mode);
    link.href = url.toString();
  }
}
