import { Controller } from "@hotwired/stimulus";
import { throttle } from "lodash";

export default class extends Controller {
  static targets = ["shadowLeft", "shadowRight", "scroll"];

  connect() {
    this.throttledOnScroll = throttle(() => this.onScroll(), 100, {
      leading: true,
      trailing: true,
    });

    this.onScroll();
    this.scrollTarget.addEventListener("scroll", this.throttledOnScroll);
  }

  disconnect() {
    this.scrollTarget.removeEventListener("scroll", this.throttledOnScroll);
    this.throttledOnScroll = null;
  }

  onScroll() {
    const remainder =
      this.scrollTarget.scrollWidth -
      this.scrollTarget.scrollLeft -
      this.scrollTarget.offsetWidth;

    this.toggleShadow(this.shadowLeftTarget, this.scrollTarget.scrollLeft > 0);
    this.toggleShadow(this.shadowRightTarget, remainder > 0);
  }

  toggleShadow(shadow, visible) {
    shadow.style.opacity = visible ? "1" : "0";
  }
}
