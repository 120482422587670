import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["entryType", "noCultureImpact", "message"];

  connect() {
    this.toggleVisibility();
  }

  toggleVisibility() {
    const currentType = this.element.querySelector(
      "input[type=radio][name='entry[entry_type]']:checked",
    )?.value;

    const transactionTypes = JSON.parse(
      this.entryTypeTarget.getAttribute("data-transaction-types"),
    );
    const noImpact = this.noCultureImpactTarget.checked;
    const isView = !transactionTypes.includes(currentType) && currentType;

    if (isView || noImpact) {
      this.messageTarget.style.removeProperty("display");
    } else {
      this.messageTarget.style.display = "none";
    }
  }
}
