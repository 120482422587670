import { Controller } from "@hotwired/stimulus";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";

export default class extends Controller {
  static targets = ["trigger"];

  connect() {
    tippy(this.element, {
      content: this.element.getAttribute("data-tooltip-html"),
      // Specifying both the input and the label allows the tooltip to be triggered by mouse AND keyboard.
      triggerTarget: this.triggerTargets,
      allowHTML: true,
      delay: 400,
      placement: "bottom",
      theme: "everest",
    });
  }

  disconnect() {
    tippy(this.element).destroy();
  }
}
