import { Controller } from "@hotwired/stimulus";
import { debounce } from "lodash";

export default class extends Controller {
  static targets = ["from", "to"];

  connect() {
    this.toTarget.addEventListener("change", this.debouncedToDateHandler());
    this.fromTarget.addEventListener("change", this.debouncedFromDateHandler());
  }

  disconnect() {
    this.toTarget.removeEventListener("change", this.debouncedToDateHandler());
    this.fromTarget.addEventListener("change", this.debouncedFromDateHandler());
  }

  submit() {
    this.dispatch("changed");
  }

  fromDateHandler() {
    const { valueAsDate } = this.fromTarget;
    this.fromTarget.setCustomValidity("");
    if (valueAsDate) {
      this.generateReport(this.fromTarget);
    }
    if (this.fromTarget.validity.valid || this.fromTarget.value === "") {
      this.submit();
    } else {
      this.fromTarget.reportValidity();
    }
  }

  toDateHandler() {
    const { valueAsDate } = this.toTarget;
    this.toTarget.setCustomValidity("");
    if (valueAsDate) {
      this.generateReport(this.toTarget);
    }
    if (this.toTarget.validity.valid || this.toTarget.value === "") {
      this.submit();
    } else {
      this.toTarget.reportValidity();
    }
  }

  debouncedFromDateHandler() {
    return debounce(() => this.fromDateHandler(), 1000);
  }
  debouncedToDateHandler() {
    return debounce(() => this.toDateHandler(), 1000);
  }
  generateReport(target) {
    const target_name = target.name.replace("_date", "");
    this.reportFutureDate(
      target.valueAsDate,
      target,
      `The "${target_name}" date must not be in the future.`,
    );
    this.reportInvalidDateRange(
      target,
      `The "to" date must not be less than the "from" date.`,
    );
  }

  reportInvalidDateRange(target, message) {
    if (
      this.toTarget.validity.valid &&
      this.fromTarget.validity.valid &&
      this.toTarget.valueAsDate &&
      this.fromTarget.valueAsDate &&
      this.toTarget.valueAsDate < this.fromTarget.valueAsDate
    ) {
      target.setCustomValidity(message);
    }
  }

  reportFutureDate(date, target, message) {
    if (date > new Date()) {
      target.setCustomValidity(message);
    }
  }
}
