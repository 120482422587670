import { Controller } from "@hotwired/stimulus";

const CHECKBOX_SELECTOR = `tbody .table__check input[type="checkbox"]`;

export default class extends Controller {
  static targets = ["buttons", "allInput", "replacedByButtons"];

  connect() {
    this.indeterminate = false;
    this.render();
  }

  toggle(_event) {
    this.render();
  }

  toggleAll(event) {
    if (this.indeterminate) {
      event.target.checked = false;
    }
    const allChecked = event.target.checked;
    this.element.querySelectorAll(CHECKBOX_SELECTOR).forEach((input) => {
      input.checked = allChecked;
    });
    this.render();
  }

  render() {
    const allCount = this.element.querySelectorAll(CHECKBOX_SELECTOR).length;
    const checkedCount = this.element.querySelectorAll(
      `${CHECKBOX_SELECTOR}:checked`,
    ).length;
    this.allInputTarget.checked = allCount == checkedCount;
    this.indeterminate = this.allInputTarget.indeterminate =
      checkedCount > 0 && allCount > checkedCount;
    this.buttonsTarget.hidden = checkedCount === 0;
    this.replacedByButtonsTargets.forEach((el) => {
      el.hidden = checkedCount !== 0;
    });
  }
}
