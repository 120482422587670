import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.element.querySelectorAll("input[type=radio]").forEach((radio) => {
      if (radio.checked) {
        this.currentValue = radio.value;
      }
    });
  }

  update(event) {
    const radio = event.target;

    if (event.keyCode && !radio.checked) {
      radio.checked = true;
      this.currentValue = radio.value;
      radio.dispatchEvent(new CustomEvent("change", { bubbles: true }));
    } else if (this.currentValue === radio.value) {
      this.currentValue = null;
      // The browser always marks the radio as checked in response to click,
      // even if we stop the propagation of the event. To work around this,
      // we use setTimeout to defer the un-check step until after the event
      // finishes.
      setTimeout(() => {
        radio.checked = false;
        radio.dispatchEvent(new CustomEvent("change", { bubbles: true }));
      }, 0);
    } else {
      this.currentValue = radio.value;
    }
  }
}
