import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["entryType", "transactionQuestion", "viewQuestion"];

  connect() {
    this.updateVisibilityOfQuestions();
  }

  typeChanged() {
    this.updateVisibilityOfQuestions();
    this.clearValidationErrors();
  }

  updateVisibilityOfQuestions() {
    const currentType = this.element.querySelector(
      "input[type=radio][name='entry[entry_type]']:checked",
    )?.value;

    const transactionTypes = JSON.parse(
      this.entryTypeTarget.getAttribute("data-transaction-types"),
    );
    const isTransaction = transactionTypes.includes(currentType);

    this.transactionQuestionTargets.forEach((target) => {
      this.showElementIf(target, isTransaction);
    });
    this.viewQuestionTargets.forEach((target) => {
      this.showElementIf(target, !isTransaction);
    });
  }

  showElementIf(target, isVisible) {
    const originalDisplay =
      target.getAttribute("data-display") ||
      getComputedStyle(target).display ||
      "block";
    target.setAttribute("data-display", originalDisplay);

    target.style.display = isVisible ? originalDisplay : "none";
  }

  clearValidationErrors() {
    this.element.querySelectorAll(".entry_form__error").forEach((el) => {
      el.style.display = "none";
    });
    this.element.querySelectorAll(".entry_form__label--error").forEach((el) => {
      el.classList.remove("entry_form__label--error");
    });
  }

  onCompanyChange() {
    this.element.removeAttribute("data-turbo");
    this.element.requestSubmit();
    this.element.setAttribute("data-turbo", "false");
  }
}
