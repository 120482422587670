import Choices from "choices.js";
import { Controller } from "@hotwired/stimulus";
import { debounce } from "lodash";

export default class extends Controller {
  static targets = ["select"];
  static values = {
    useFactSet: Boolean,
  };

  connect() {
    if (this.isAlreadyConnected) return;

    const choiceValue = this.useFactSetValue ? "symbol" : "fds_id";
    const noResultsText = () => this.factsetError || "There are no results";
    this.choices = new Choices(this.selectTarget, {
      allowHTML: true,
      placeholder: false,
      searchChoices: false,
      shouldSort: false,
      removeItemButton: true,
      noChoicesText: noResultsText,
      noResultsText,
      itemSelectText: "Select ⏎",
      classNames: {
        highlightedState: "company_select__company--highlighted",
      },
    });
    const component = this.choices;
    // If user has pre-selected a value, make sure that value is in the list of choices so that
    // it can be rendered properly.
    const existingChoices = JSON.parse(
      this.selectTarget.getAttribute("data-choices"),
    );
    if (existingChoices.length > 0) {
      component.setChoices(existingChoices, choiceValue, "name", true);
      component.setChoiceByValue(existingChoices[0][choiceValue]);
    }

    component.input.element.addEventListener(
      "input",
      debounce(async (e) => {
        let companies = [];
        const response = await fetch(
          `/api/company_suggestions?q=${encodeURIComponent(
            e.target.value,
          )}&useFactSet=${this.useFactSetValue}`,
          {
            headers: {
              Accept: "application/json",
              "X-Requested-With": "XMLHttpRequest",
            },
          },
        );

        if (response.ok) {
          companies = await response.json();
          this.factsetError = null;
        } else {
          this.factsetError =
            "The FactSet IdLookup API is temporarily unavailable. Try typing your search again in a few minutes.";
        }

        const choiceValue = this.useFactSetValue ? "symbol" : "fds_id";
        component.setChoices(companies, choiceValue, "name", true);
      }, 400),
    );
    this.dispatch("choices-initialized");
  }

  get isAlreadyConnected() {
    return !!this.selectTarget.dataset.choice;
  }

  disconnect() {
    if (this.choices) {
      this.choices.destroy();
    }
  }
}
