import { Controller } from "@hotwired/stimulus";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/shift-away.css";

export default class extends Controller {
  static targets = ["template"];

  connect() {
    tippy(this.element, {
      animation: "shift-away",
      arrow: false,
      content: this.templateTarget.content.cloneNode(true),
      delay: 0,
      duration: 200,
      interactive: true,
      offset: [-25, 5],
      onShow: () => this.onShow(),
      onHide: () => this.onHide(),
      placement: "bottom-start",
      popperOptions: {
        // Disable the flip behavior so the menu doesn't pop under the sidebar on narrow screens
        modifiers: [{ name: "flip", options: { fallbackPlacements: [] } }],
      },
      theme: "menu",
      trigger: "click",
    });
  }

  disconnect() {
    tippy(this.element).destroy();
  }

  onShow() {
    this.element.classList.add("button--active");
  }

  onHide() {
    this.element.classList.remove("button--active");
  }
}
