import FilterStatusIndicatorController from "./filter_status_indicator_controller";

export default class extends FilterStatusIndicatorController {
  shouldShowIndicator() {
    return [
      ...this.element.querySelectorAll("input[type=date]"),
      ...this.element.querySelectorAll("input[type=text]"),
    ].find((input) => input.value);
  }
}
