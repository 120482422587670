import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["indicator"];

  connect() {
    this.updateIndicator();
  }

  updateIndicator() {
    if (!this.element.open && this.shouldShowIndicator()) {
      this.indicatorTarget.classList.add("filter__indicator--active");
    } else {
      this.indicatorTarget.classList.remove("filter__indicator--active");
    }
  }

  shouldShowIndicator() {
    throw new Error("Implement in child class");
  }
}
