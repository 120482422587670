import { Controller } from "@hotwired/stimulus";

class FlashController extends Controller {
  connect() {
    setTimeout(() => {
      this.hide(this.element);
      setTimeout(() => {
        this.remove(this.element);
      }, 500);
    }, 3000);
  }

  hide(target) {
    target.classList.add("flash__container--fade_out");
  }

  remove(target) {
    target.remove();
  }
}

export default FlashController;
