import { Controller } from "@hotwired/stimulus";
import { clearTargetTurboFrame } from "./utils.js";

export default class extends Controller {
  static targets = ["clear"];

  connect() {
    this.turboRenderListener = () => this.updateClearVisibility();
    document.addEventListener("turbo:load", this.turboRenderListener);
    this.turboRenderListener();
  }

  disconnect() {
    document.removeEventListener("turbo:load", this.turboRenderListener);
  }

  submit() {
    clearTargetTurboFrame(this.element);
    this.element.dispatchEvent(
      new CustomEvent("submit", { bubbles: true, cancelable: true }),
    );
  }

  updateClearVisibility() {
    if (!this.hasClearTarget) return;

    this.clearTarget.hidden = !this.isFilterApplied;
  }

  get isFilterApplied() {
    const params = new URL(window.location).searchParams;
    return [...params.entries()].some(([_name, value]) => value !== "");
  }
}
