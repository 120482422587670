import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["deselect", "input", "submit"];

  connect() {
    this.updateSubmitState();
  }

  check() {
    this.updateSubmitState();
  }

  deselect() {
    this.inputTargets.forEach((input) => {
      input.checked = false;
    });
    this.updateSubmitState();
  }

  updateSubmitState() {
    const someChecked = this.inputTargets.some((input) => input.checked);
    this.deselectTarget.hidden = !someChecked;
    this.submitTarget.disabled = !someChecked;
  }
}
