import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["personal", "shared"];

  connect() {
    this.update();
  }

  personalTargetConnected(_el) {
    this.update();
  }

  sharedTargetConnected(_el) {
    this.update();
  }

  update() {
    const shared = !!this.element.querySelector(
      "#collection_shared_true:checked",
    );

    this.personalTargets.forEach((target) => {
      target.hidden = shared;
    });
    this.sharedTargets.forEach((target) => {
      target.hidden = !shared;
    });
  }
}
