import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tab", "panel"];

  connect() {
    this.normalizePanelHeights();
    this.togglePanel(0);
  }

  click(event) {
    const index = this.tabTargets.indexOf(event.currentTarget);
    this.togglePanel(index);
    event.preventDefault();
  }

  normalizePanelHeights() {
    const heights = this.panelTargets.map((panel) => panel.offsetHeight);
    const tallestHeight = Math.max(...heights);

    this.panelTargets.forEach((panel) => {
      panel.style.minHeight = `${tallestHeight}px`;
    });
  }

  togglePanel(index) {
    this.hideAllPanels();
    this.clearSelectedTab();

    this.tabTargets[index].setAttribute("aria-selected", "true");

    this.panelTargets[index].style.display = "flex";
  }

  hideAllPanels() {
    this.panelTargets.forEach((panel) => {
      panel.style.display = "none";
    });
  }

  clearSelectedTab() {
    this.tabTargets.forEach((tab) => {
      tab.setAttribute("aria-selected", "false");
    });
  }
}
