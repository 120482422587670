import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["content"];

  connect() {
    if (!this.element.open) {
      this.element.showModal();
    }
  }

  close() {
    if (this.element.open) {
      this.element.close();
    }
    this.element.remove();
    this.modalTurboFrame.src = null;
  }

  clickOutside(event) {
    if (!this.contentTarget.contains(event.target)) {
      this.close();
    }
  }

  get modalTurboFrame() {
    return document.querySelector("turbo-frame[id='modal']");
  }
}
