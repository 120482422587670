import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tab"];
  categories = ["positive", "negative", "culture", "typology", "moat_source"];

  connect() {
    this.resetTabCount();
  }

  updateCount(event) {
    const category = event.target.getAttribute("data-category");
    this.updateTabCount(category);
  }

  resetTabCount() {
    this.categories.forEach((category) => {
      this.updateTabCount(category);
    });
  }

  updateTabCount(category) {
    const categoryCount = this.element.querySelectorAll(
      `input[type=checkbox][data-category=${category}]:checked`,
    ).length;
    const tabLabel = this.element.querySelector(
      `.pill_tabs__label[data-tab-category=${category}]`,
    );
    const tabNotification = tabLabel.nextElementSibling;
    if (categoryCount > 0) {
      tabNotification.classList.remove("button__notification--inactive");
      tabLabel.classList.add("pill_tabs_label--compressed");
      tabNotification.textContent = categoryCount;
    } else {
      tabNotification.classList.add("button__notification--inactive");
      tabLabel.classList.remove("pill_tabs_label--compressed");
    }
  }
}
