import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    url: String,
  };

  showDetailedEntry(event) {
    // Skip if user was trying to click a button or check box in the row
    if (["INPUT", "LABEL"].includes(event.target.tagName)) return;

    this.modalTurboFrame.src = this.urlValue;
  }

  enableActions(_event) {
    this.buttons.forEach((button) => {
      const form = button.getAttribute("data-form");
      if (form) button.setAttribute("form", form);
    });
  }

  disableActions(_event) {
    this.buttons.forEach((button) => {
      const form = button.getAttribute("data-form");
      if (form) button.removeAttribute("form");
    });
  }

  get buttons() {
    return this.element.querySelectorAll("button");
  }

  get modalTurboFrame() {
    return document.querySelector("turbo-frame[id='modal']");
  }
}
