import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["filters", "button"];

  connect() {
    this.isAdvancedFilterApplied ? this.show() : this.hide();
  }

  toggle() {
    this.isHidden ? this.show() : this.hide();

    if (this.isHidden) {
      this.deselectAllInputs();
      this.collapseAllDetails();
    }
  }

  get isHidden() {
    return this.filtersTarget.style.display === "none";
  }

  get isAdvancedFilterApplied() {
    return this.findCheckedInputs().length > 0;
  }

  show() {
    this.filtersTarget.style.removeProperty("display");
    this.buttonTarget.innerText = this.buttonTarget.innerText.replace(
      "Add",
      "Remove",
    );
  }

  hide() {
    this.filtersTarget.style.display = "none";
    this.buttonTarget.innerText = this.buttonTarget.innerText.replace(
      "Remove",
      "Add",
    );
  }

  deselectAllInputs() {
    const inputs = this.findCheckedInputs();
    if (inputs.length === 0) return;

    inputs.forEach((el) => el.click());
  }

  collapseAllDetails() {
    this.element.querySelectorAll("details").forEach((el) => {
      el.open = false;
    });
  }

  findCheckedInputs() {
    return this.element.querySelectorAll(`input:checked`);
  }
}
