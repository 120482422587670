import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["categorySelect", "groupSelect"];

  connect() {
    this.updateGroupOptions();
  }

  updateGroupOptions() {
    const currentCategory = this.categorySelectTarget.value;
    this.groupSelectTargets.forEach((groupSelect) => {
      const enabled =
        groupSelect.getAttribute("data-category") == currentCategory;
      groupSelect.hidden = !enabled;
      groupSelect.querySelector("select").disabled = !enabled;
    });
  }
}
